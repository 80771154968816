import React from 'react';
import { css, cx } from '@emotion/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowDown } from '@fortawesome/free-solid-svg-icons';

const Styles = {
  olderVersionsToggle: css({
    appearance: 'none',
    display: 'inline-block',
    outline: 'none',
    border: 'none',
    margin: '0',
    padding: '0',
    background: 'transparent',
  }),
};

function CollapseToggle({
  text,
  icon = faCircleArrowDown,
  linkStyle = true,
  className,
  onClick,
}) {
  return (
    <button
      className={cx(
        Styles.olderVersionsToggle,
        className,
        linkStyle ? 'bp-link' : '',
      )}
      onClick={onClick}
    >
      {text && <>{text}&nbsp;</>}
      <FontAwesomeIcon icon={icon} />
    </button>
  );
}

export default CollapseToggle;
