import { createBrowserRouter } from 'react-router-dom';
import { loader as versionsLoader } from 'ApiDocs/VersionList';
import ApiDocs from 'ApiDocs';
import JsonDoc, { loader as jsonLoader } from 'ApiDocs/JSONDoc';
import { loader as introLoader } from 'Common/layout/IntroFolder';
import ErrorPage from 'Common/ErrorPage';
import React from 'react';
import ax from 'axios';
import { getCachedData } from 'utils/loaderCache';
import { RouterBase } from 'App';
import Gdpr from 'Gdpr';
import GdprVersionList, {
  loader as gdprVersionLoader,
} from 'Gdpr/GdprVersionList';
import VersionList from 'ApiDocs/VersionList';

export const isGdprDomain = window.location.host.split('.')[0] === 'gdpr';

export let axios = ax.create();
if (!isGdprDomain) {
  axios.defaults.auth = {
    username: 'brightpick-customer',
    password: 'Br1ghtp1cK2022',
  };
}

export const API_DOCS_FOLDER = '/data-api';
export const GDPR_FOLDER = '/data-gdpr';

export const docsRouter = isGdprDomain
  ? null
  : createBrowserRouter([
      {
        path: '/',
        element: <RouterBase />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: '',
            index: true,
            element: <ApiDocs />,
            loader: async () =>
              await getCachedData('apiHome', introLoader, {
                baseFolder: API_DOCS_FOLDER,
              }),
          },
          {
            path: '/:dir',
            element: <VersionList />,
            loader: async (params) => {
              params.baseFolder = API_DOCS_FOLDER;
              return await getCachedData(
                `docs/${params?.params?.dir}`,
                versionsLoader,
                params,
              );
            },
          },
          {
            path: '/:dir/:filePath',
            element: <JsonDoc />,
            loader: jsonLoader,
          },
        ],
      },
    ]);

export const gdprRouter = isGdprDomain
  ? createBrowserRouter([
      {
        path: '/',
        element: <RouterBase />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: '',
            index: true,
            element: <Gdpr />,
            loader: async () =>
              await getCachedData('gdprHome', introLoader, {
                baseFolder: GDPR_FOLDER,
              }),
          },
          {
            path: '/:dir',
            element: <GdprVersionList />,
            loader: async (params) => {
              params.baseFolder = GDPR_FOLDER;
              return await getCachedData(
                `gdpr/${params?.params?.dir}`,
                gdprVersionLoader,
                params,
              );
            },
          },
        ],
      },
    ])
  : null;

export function isGdpr() {
  return isGdprDomain;
}
