import React from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import Card from 'Common/layout/Card';
import { axios } from 'config';
import { handleAxiosError } from 'utils';
import VersionGroup from 'ApiDocs/VersionGroup';
import ApiOlderVersions from 'ApiDocs/ApiOlderVersions';

const API_DESCRIPTION_FULFILLMENT =
  'OpenAPI specification and integration guide for all the necessary interactions with the automated warehouse (fulfillment): product catalog management, order processing, decanting, inventory, recall, storage statistics, and health-checks. ';
const API_DESCRIPTION_DISPATCH =
  'OpenAPI specification and integration guide for all the necessary interactions with the automated warehouse (dispatch): incoming tote information, tote placement, conveyor integration, route handling, storage statistics, and health-checks. ';

function VersionList() {
  const location = useLocation();
  const isRouteFulfillment = location.pathname.toLowerCase() === '/fulfillment';

  const { latestVersion, olderVersions, olderVersionsPath } = useLoaderData();

  return (
    <Card>
      <VersionGroup
        folderName={latestVersion.name}
        description={
          isRouteFulfillment
            ? API_DESCRIPTION_FULFILLMENT
            : API_DESCRIPTION_DISPATCH
        }
        documentFiles={latestVersion.files}
        folderPath={latestVersion.path}
        isLatest={true}
      />

      {olderVersions.length ? (
        <ApiOlderVersions
          versions={olderVersions}
          versionsPath={olderVersionsPath}
        />
      ) : null}
    </Card>
  );
}

async function loader({ params, baseFolder }) {
  try {
    const basePath = `${baseFolder}/${params.dir}`;
    const versionsDir = await axios.get(`${basePath}`);
    const latestVersion = versionsDir.data.find(
      (file) => file.type === 'directory' && file.name !== 'older',
    );
    const latestDir = {
      name: '',
      files: [],
      path: '',
    };
    if (latestVersion) {
      const latestContent = await axios.get(
        `${basePath}/${latestVersion.name}`,
      );
      latestDir.name = latestVersion.name;
      latestDir.files = latestContent.data;
      latestDir.path = `${basePath}/${latestVersion.name}`;
    }
    const olderDir = await axios.get(`${basePath}/older`);

    return {
      latestVersion: latestDir,
      olderVersions: olderDir.data,
      olderVersionsPath: `${basePath}/older`,
    };
  } catch (e) {
    handleAxiosError(e);
    return {
      latest: {},
      olderVersions: [],
      olderVersionsPath: '',
    };
  }
}

export default VersionList;
export { loader };
