import React from 'react';
import { axios } from 'config';
import { handleAxiosError } from 'utils';
import { useLoaderData } from 'react-router-dom';
import Card from 'Common/layout/Card';
import GdprLanguageGroup from 'Gdpr/GdprLanguageGroup';
import GdprOlderVersions from 'Gdpr/GdprOlderVersions';
import Footer from 'Common/Footer';
import { css } from '@emotion/css';

const Styles = {
  card: css({
    width: '100%',
  }),
};

function GdprVersionList() {
  const { latestVersion, olderVersions, olderVersionsPath } = useLoaderData();
  const latestDocs = {
    main: latestVersion.mainLanguage,
    other: latestVersion.otherLanguages,
  };

  return (
    <Card
      footer={<Footer mailAddress={'gdpr@brightpick.ai'} />}
      className={Styles.card}
    >
      <GdprLanguageGroup
        folderName={latestVersion.name}
        languageDocuments={latestDocs}
        folderPath={latestVersion.path}
        isLatest={true}
      />

      {olderVersions?.length ? (
        <GdprOlderVersions
          versions={olderVersions}
          versionsPath={olderVersionsPath}
        />
      ) : null}
    </Card>
  );
}

async function loader({ params, baseFolder }) {
  try {
    const basePath = `${baseFolder}/${params.dir}`;
    const versionsDir = await axios.get(`${basePath}`);
    const latestVersion = versionsDir.data.find(
      (file) => file.type === 'directory' && file.name !== 'older',
    );
    const latestDir = {
      name: '',
      mainLanguage: {},
      otherLanguages: [],
      path: '',
    };
    if (latestVersion) {
      const latestContent = await axios.get(
        `${basePath}/${latestVersion.name}`,
      );
      const otherLanguages = await axios.get(
        `${basePath}/${latestVersion.name}/other_languages`,
      );
      const mainLangFile = latestContent.data.find(
        (item) => item.type === 'file',
      );

      latestDir.name = latestVersion.name;
      latestDir.mainLanguage = mainLangFile;
      latestDir.otherLanguages = otherLanguages.data;
      latestDir.path = `${basePath}/${latestVersion.name}`;
    }
    const olderDir = await axios.get(`${basePath}/older`);

    return {
      latestVersion: latestDir,
      olderVersions: olderDir.data,
      olderVersionsPath: `${basePath}/older`,
    };
  } catch (e) {
    handleAxiosError(e);
    return {
      latestVersion: {},
      olderVersions: [],
      olderVersionsPath: '',
    };
  }
}

export default GdprVersionList;
export { loader };
