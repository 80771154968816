let loaderCache = {};

async function getCachedData(id, loaderFunction, params) {
  if (id in loaderCache) {
    return loaderCache[id];
  }
  const data = await loaderFunction(params);
  if (data) {
    loaderCache[id] = data;
  }
  return data;
}

export default loaderCache;
export { getCachedData };
