import React, { createContext, useState } from 'react';
import { axios } from 'config';
import { handleAxiosError } from 'utils';

const LoaderState = {
  INITIAL: 'initial',
  LOADING: 'loading',
  READY: 'ready',
  ERROR: 'error',
};

const RedocLoaderContext = createContext(null);

function RedocLoaderProvider({ children }) {
  const [loaderData, setLoaderData] = useState(null);
  const [loaderState, setLoaderState] = useState(LoaderState.INITIAL);

  async function load(url) {
    setLoaderData(null);
    setLoaderState(LoaderState.LOADING);
    try {
      const resp = await axios.get(url);
      setLoaderData(resp.data);
      setLoaderState(LoaderState.READY);
    } catch (error) {
      handleAxiosError(error);
      setLoaderData({ error });
      setLoaderState(LoaderState.ERROR);
    }
  }

  const loaderContext = {
    load,
    data: loaderData,
    state: loaderState,
  };

  return (
    <RedocLoaderContext.Provider value={loaderContext}>
      {children}
    </RedocLoaderContext.Provider>
  );
}

export default RedocLoaderContext;
export { RedocLoaderProvider, LoaderState };
