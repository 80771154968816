import React from 'react';
import { useRouteError } from 'react-router-dom';

function ErrorPage(props) {
  const error = useRouteError();
  //TODO: detect if url matches any route. If not display 'Page not found'

  return <div>{error.message}</div>;
}

export default ErrorPage;
