import React from 'react';
import { css } from '@emotion/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';
import { Link, useLocation } from 'react-router-dom';


const Styles = {
  footer: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    a: {
      fontSize: '14px',
    },
  }),
};

function Footer({mailAddress = 'kilian@brightpick.ai'}) {
    const location = useLocation();
    const isRouteHome = location.pathname.toLowerCase() === '/';

    return (
        <footer className={Styles.footer}>
            <div className={css({paddingRight: '65px'})}>
                <a href={`mailto:${mailAddress}`}>
                    <FontAwesomeIcon icon={faCircleQuestion}/>
                </a>
            </div>
            {isRouteHome ? null :
                <Link to={'/'}>
                    <Button variant={'secondary'}>
                        <FontAwesomeIcon icon={faChevronLeft}/>{' Go back'}
                    </Button>
                </Link>
            }
            <div>
                <a href="https://brightpick.ai">brightpick.ai</a>
            </div>
        </footer>
    );
}

export default Footer;
