import React, { useMemo } from 'react';
import { css } from '@emotion/css';
import Button from 'Common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { colors, getHexHighlightColor } from 'style';
import { formatFolderName } from 'utils';

const Styles = {
  container: css({
    marginBottom: '2em',
  }),
  heading: css({
    color: 'rgb(45, 25, 85)',
    fontSize: '22px',
    margin: '20px 0px 20px 0px',
    textAlign: 'center',
    span: {
      textTransform: 'capitalize',
    },
  }),
  description: css({
    textAlign: 'left',
    margin: '20px 0px',
  }),
  buttonsWrapper: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    columnGap: '0.75em',
  }),
  btnGroup: css({
    '& > a': {
      border: 'none',
      color: 'white !important',
      textDecoration: 'none !important',
    },
  }),
  pdfButton: css({
    borderTopRightRadius: '0 !important',
    borderBottomRightRadius: '0 !important',
    borderRight: `2px solid ${colors.primaryLight} !important`,
    '&:hover, &:active, &:focus': {
      backgroundColor: `${getHexHighlightColor(colors.primary)} !important`,
    },
  }),
  downloadButton: css({
    paddingLeft: '0.5em !important',
    paddingRight: '0.5em !important',
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
    backgroundColor: `${colors.primaryDarker} !important`,
    '&:hover, &:active, &:focus': {
      backgroundColor: `${getHexHighlightColor(
        colors.primaryDarker,
      )} !important`,
    },
    svg: {
      fontSize: '0.9rem',
    },
  }),
  separator: css({
    borderTop: '1px solid rgba(0,0,0,.1)',
    opacity: 1,
  }),
};

function sortByMainFile(a, b) {
  const byMain = b.isMain - a.isMain;
  if (byMain) return byMain;
  return a.name.localeCompare(b.name);
}

/**
 *
 * @param name
 * @returns {string} Text after last dash '-' without file type (eg. '.pdf')
 */
function formatPdfName(name) {
  return name.split('-').pop().split('.')[0];
}

function FileButton({ url, name, originalName }) {
  return (
    <div className={Styles.btnGroup}>
      <Button as={'a'} href={url} className={Styles.pdfButton}>
        {formatPdfName(name)}
      </Button>

      <Button
        as={'a'}
        href={url}
        download={originalName}
        className={Styles.downloadButton}
      >
        <FontAwesomeIcon icon={faDownload} />
      </Button>
    </div>
  );
}

function GdprLanguageGroup({
  folderName,
  languageDocuments = { main: {}, other: [] },
  folderPath,
  isLatest = false,
}) {
  const { buttons, mainFileName } = useMemo(() => {
    if (!languageDocuments?.main?.name) {
      return { buttons: [], mainFileName: '' };
    }

    const mainFileName = formatFolderName(
      languageDocuments.main.name.split('-')[0],
    );

    return {
      mainFileName,
      buttons: [...languageDocuments?.other, languageDocuments.main]
        .map((file) => {
          const isMain = file.name === languageDocuments.main.name;
          return {
            name: formatPdfName(file.name),
            originalName: file.name,
            url: `${folderPath}${isMain ? '' : '/other_languages'}/${
              file.name
            }`,
            isMain,
          };
        })
        .sort(sortByMainFile),
    };
  }, [languageDocuments, folderPath]);

  return (
    <>
      <div className={Styles.container}>
        <h1 className={Styles.heading}>
          {isLatest && (
            <>
              {mainFileName}
              <br />
            </>
          )}
          {folderName}
        </h1>
        <div className={Styles.buttonsWrapper}>
          {buttons.map((button) => (
            <FileButton key={button.name} {...button} />
          ))}
        </div>
      </div>
      {!isLatest && <hr className={Styles.separator} />}
    </>
  );
}

export default GdprLanguageGroup;
