const colors = {
  primary: '#FF4800',
  primaryLight: '#ffa365',
  primaryDarker: '#d53504',
  secondary: '#2D1955',
};

function getHexHighlightColor(color = '#ffffff') {
  return color + 'CC';
}

export { colors, getHexHighlightColor };
