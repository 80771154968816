import React from 'react';
import { css } from '@emotion/css';
import logo from '../assets/logo.svg';

const Styles = {
  header: css({
    marginBottom: '45px',
  }),
  logo: css({
    display: 'inline-block',
    width: '350px',
    textAlign: 'left',
  }),
};

function LogoHeader() {
  return (
    <header className={Styles.header}>
      <img src={logo} alt={'Brightpick'} className={Styles.logo} />
    </header>
  );
}

export default LogoHeader;
