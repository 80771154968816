import React, { useEffect, useState } from 'react';
import { axios } from 'config';
import { handleAxiosError } from 'utils';
import { getCachedData } from 'utils/loaderCache';
import { Collapse } from 'react-bootstrap';
import GdprLanguageGroup from 'Gdpr/GdprLanguageGroup';
import { css } from '@emotion/css';
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons';
import Button from 'Common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Styles = {
  collapseButton: css({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2.2em',
    height: '2.2em',
    marginBottom: '1em',
    padding: '0px !important',
    fontSize: '1.2rem !important',
    lineHeight: '1 !important',
    borderRadius: '99px !important',
  }),
};

function sortByName(a, b) {
  return b.name.localeCompare(a.name);
}

function GdprOlderVersions({ versions, versionsPath }) {
  const [olderVersions, setOlderVersions] = useState({});
  const [collapseOpen, setCollapseOpen] = useState(false);

  function toggleCollapse() {
    setCollapseOpen(!collapseOpen);
  }

  useEffect(() => {
    if (versions?.length) {
      getCachedData(`gdpr/${versionsPath}`, () =>
        loadVersions(versionsPath, versions),
      ).then((data) => {
        setOlderVersions(data);
      });
    }
  }, [versions, versionsPath]);
  return (
    <>
      <Button
        variant={'secondary'}
        className={Styles.collapseButton}
        onClick={toggleCollapse}
      >
        <FontAwesomeIcon icon={faBoxArchive} />
      </Button>
      <Collapse in={collapseOpen}>
        <div>
          {Object.values(olderVersions)
            .sort(sortByName)
            .map((dir) => (
              <GdprLanguageGroup
                key={dir.name}
                folderName={dir.name}
                languageDocuments={{
                  main: dir.mainLanguage,
                  other: dir.otherLanguages,
                }}
                folderPath={dir.path}
              />
            ))}
        </div>
      </Collapse>
    </>
  );
}

async function loadVersions(path, versionDirs) {
  try {
    const versionsData = {};
    for (const dir of versionDirs) {
      versionsData[dir.name] = {
        name: dir.name,
        mainLanguage: {},
        otherLanguages: [],
        path: `${path}/${dir.name}`,
      };
      const dirContent = await axios.get(`${path}/${dir.name}`);
      versionsData[dir.name].mainLanguage = dirContent.data.find(
        (item) => item.type === 'file',
      );

      const otherLanguages = await axios.get(
        `${path}/${dir.name}/other_languages`,
      );
      versionsData[dir.name].otherLanguages = otherLanguages.data;
    }

    return versionsData;
  } catch (err) {
    handleAxiosError(err);
    return {};
  }
}

export default GdprOlderVersions;
