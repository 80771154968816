import React, { useEffect, useState } from 'react';
import { axios } from 'config';
import VersionGroup from 'ApiDocs/VersionGroup';
import { getCachedData } from 'utils/loaderCache';
import { Collapse } from 'react-bootstrap';
import CollapseToggle from 'Common/CollapseToggle';

function sortByVersion(a, b) {
  return a[0] <= b[0];
}

function ApiOlderVersions({ versions, versionsPath }) {
  const [versionsContent, setVersionsContent] = useState({});
  const [olderOpen, setOlderOpen] = useState(false);

  function toggleOlderVersions() {
    setOlderOpen(!olderOpen);
  }

  useEffect(() => {
    if (versions && versions.length) {
      getCachedData(`docs/${versionsPath}`, () =>
        Promise.all(loadVersions(versionsPath, versions)),
      ).then((data) => {
        const contentsByVersion = data.reduce((contentsObj, respData) => {
          const fName = respData.config.url.split('/').slice(-1);

          contentsObj[fName] = respData.data;

          return contentsObj;
        }, {});
        setVersionsContent(contentsByVersion);
      });
    }
  }, [versions, versionsPath]);

  return (
    <>
      <CollapseToggle
        text={'Older versions of API'}
        onClick={toggleOlderVersions}
      />
      <Collapse in={olderOpen}>
        <div>
          {Object.entries(versionsContent)
            .sort(sortByVersion)
            .map(([fName, fFiles]) => (
              <VersionGroup
                key={fName}
                folderName={fName}
                documentFiles={fFiles}
                folderPath={`${versionsPath}/${fName}`}
              />
            ))}
        </div>
      </Collapse>
    </>
  );
}

function loadVersions(path, versionDirs) {
  return versionDirs.map((vDir) => axios.get(`${path}/${vDir.name}`));
}

export default ApiOlderVersions;
