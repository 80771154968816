function handleAxiosError(error) {
  if (error.response) {
    console.error('Error response', error.response.data);
  } else if (error.request) {
    console.error('Request error', error.request);
  } else {
    console.error('Error', error.message);
  }
}

function formatFolderName(folderName) {
  return folderName.replace(/_/g, ' ');
}

export {handleAxiosError, formatFolderName}
