import React from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import Button from 'Common/Button';
import { formatFolderName, handleAxiosError } from 'utils';
import { css } from '@emotion/css';
import Card from 'Common/layout/Card';
import { axios, isGdpr } from 'config';
import Footer from 'Common/Footer';

const Styles = {
  description: css({
    margin: '20px 0px',
  }),
  buttonsWrapper: (isGdpr) =>
    css({
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      columnGap: '1em',
      rowGap: '1em',
      button: {
        textTransform: 'capitalize',
      },
      ...(isGdpr && {
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        flexWrap: 'nowrap',
      }),
    }),
};

function DirectoriesAsButtons({ folders = [] }) {
  return (
    <div className={Styles.buttonsWrapper(isGdpr())}>
      {folders.map((dir) => (
        <Link key={dir.name} to={dir.name}>
          <Button>{formatFolderName(dir.name)}</Button>
        </Link>
      ))}
    </div>
  );
}

function IntroFolder({ introText = '', mailAddress }) {
  const folderContent = useLoaderData();
  const folders = folderContent.filter((item) => item.type === 'directory');

  return (
    <Card footer={<Footer mailAddress={mailAddress} />}>
      <p className={Styles.description}>{introText}</p>
      <DirectoriesAsButtons folders={folders} />
    </Card>
  );
}

async function loader({ baseFolder }) {
  try {
    const resp = await axios.get(baseFolder);
    return resp.data;
  } catch (error) {
    handleAxiosError(error);
    return [];
  }
}

export default IntroFolder;
export { DirectoriesAsButtons, loader };
