import React from 'react';
import IntroFolder from 'Common/layout/IntroFolder';

function ApiDocs() {
  return (
    <IntroFolder
      introText={
        'Integration documentation for Brightpick automated warehouses.'
      }
    />
  );
}

export default ApiDocs;
