import React from 'react';
import IntroFolder from 'Common/layout/IntroFolder';

function Gdpr() {
  return (
    <IntroFolder
      introText={
        'Information materials on the processing of personal data through camera equipment for specific regions.'
      }
      mailAddress={'gdpr@brightpick.ai'}
    />
  );
}

export default Gdpr;
