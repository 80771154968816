import React, { useMemo } from 'react';
import { css } from '@emotion/css';
import { Outlet, RouterProvider } from 'react-router-dom';
import { docsRouter, gdprRouter, isGdprDomain } from 'config';
import { RedocLoaderProvider } from 'Common/RedocLoaderContext';

const AppStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  margin: '0',
  paddingTop: '40px',
  paddingBottom: '40px',
  overflow: 'hidden',
});

export function RouterBase() {
  return (
    <section className={AppStyle}>
      <Outlet />
    </section>
  );
}

function App() {
  const router = useMemo(() => {
    if (isGdprDomain) {
      return gdprRouter;
    }

    return docsRouter;
  }, []);

  return (
    <RedocLoaderProvider>
      <RouterProvider router={router} />
    </RedocLoaderProvider>
  );
}

export default App;
