import React from 'react';
import { Button as BSButton } from 'react-bootstrap';
import { css, cx } from '@emotion/css';
import { colors } from 'style';

const Style = css({
  '&.btn.btn-warning': {
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    borderRadius: '0.2em',
    letterSpacing: '0.2px',
    color: '#fff',
  },
});

function Button({ className, children, variant = 'warning', ...rest }) {
  return (
    <BSButton variant={variant} className={cx(Style, className)} {...rest}>
      {children}
    </BSButton>
  );
}

export default Button;
