import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from '../Common/Button';
import { css } from '@emotion/css';

const Styles = {
  container: css({
    marginBottom: '2em',
  }),
  heading: css({
    color: 'rgb(45, 25, 85)',
    fontSize: '22px',
    margin: '20px 0px 20px 0px',
    textAlign: 'center',
    span: {
      textTransform: 'capitalize',
    },
  }),
  description: css({
    textAlign: 'left',
    margin: '20px 0px',
  }),
  buttonsWrapper: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    columnGap: '0.25em',
  }),
  separator: css({
    borderTop: '1px solid rgba(0,0,0,.1)',
    opacity: 1,
  }),
};

const clientInterfaceRegex = /customer-side/g;
const brightpickInterfaceRegex = /brightpick-side/g;

function JsonButton({ url, name, type, folderName, isLatest }) {
  const buttonText = useMemo(() => {
    if (name.search(clientInterfaceRegex) >= 0) {
      return 'Client interface';
    } else if (name.search(brightpickInterfaceRegex) >= 0) {
      return 'Brightpick interface';
    }

    return name;
  }, [name]);

  return (
    <Link to={`${folderName}~${name}.${type}${isLatest ? '~l' : ''}`}>
      <Button>{buttonText}</Button>
    </Link>
  );
}

function PdfButton({ url, name }) {
  const buttonText = useMemo(() => {
    return name.replace(/-/g, ' ');
  }, [name]);

  return (
    <a href={`${url}`}>
      <Button>{buttonText}</Button>
    </a>
  );
}

function sortByFileType(a, b) {
  if (a.type !== b.type) {
    return a.type === 'pdf';
  }
  return 0;
}

function VersionGroup({
  folderName,
  description,
  documentFiles,
  folderPath,
  isLatest,
}) {
  const { dir } = useParams();

  const buttons = useMemo(() => {
    if (!documentFiles) {
      return [];
    }

    return documentFiles
      .map((file) => {
        const fileType = file.name.split('.').slice(-1)[0];
        const fileName = file.name.replace(`.${fileType}`, '');

        return {
          type: fileType,
          name: fileName,
          folderName,
          url: `${folderPath}/${file.name}`,
        };
      })
      .sort(sortByFileType);
  }, [documentFiles, folderPath, folderName]);

  return (
    <>
      <div className={Styles.container}>
        <h1 className={Styles.heading}>
          <span>{dir}</span> API documentation {folderName}
        </h1>
        {description && <p className={Styles.description}>{description}</p>}
        <div className={Styles.buttonsWrapper}>
          {buttons.map((button, index) =>
            button.type === 'pdf' ? (
              <PdfButton key={index} {...button} />
            ) : (
              <JsonButton key={index} {...button} isLatest={isLatest} />
            ),
          )}
        </div>
      </div>
      {!isLatest && <hr className={Styles.separator} />}
    </>
  );
}

export default VersionGroup;
