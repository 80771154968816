import React from 'react';
import { css, cx } from '@emotion/css';
import LogoHeader from 'Common/LogoHeader';
import Footer from 'Common/Footer';

const Styles = {
  card: css({
    background: '#fff',
    maxWidth: '800px',
    maxHeight: '650px',
    overflowY: 'auto',
    padding: '40px',
    margin: '0 auto',
    textAlign: 'center',
  }),
  content: css({
    margin: '20px 0',
  }),
};

function Card({
  header = <LogoHeader />,
  footer = <Footer />,
  children,
  className,
}) {
  return (
    <div className={cx(Styles.card, className)}>
      {header}
      <div className={Styles.content}>{children}</div>
      {footer}
    </div>
  );
}

export default Card;
