import React from 'react';
import { RedocStandalone } from 'redoc';
import { css } from '@emotion/css';
import { API_DOCS_FOLDER, axios } from 'config';
import { handleAxiosError } from 'utils';
import { useLoaderData } from 'react-router-dom';

const Styles = {
  root: css({
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
    display: 'block',
    background: 'white',
  }),
};

function JsonDoc() {
  const jsonSpec = useLoaderData();

  return (
    <div className={Styles.root}>
      <RedocStandalone spec={jsonSpec} />
    </div>
  );
}

async function loader({ params }) {
  const { dir, filePath } = params;
  const [version, fileName, latest] = filePath.split('~');

  try {
    const resp = await axios.get(
      `${API_DOCS_FOLDER}/${dir}${
        !latest ? '/older' : ''
      }/${version}/${fileName}`,
    );
    return resp.data;
  } catch (error) {
    handleAxiosError(error);
    return null;
  }
}

export default JsonDoc;
export { loader };
